import { Box, Container, Grid, Heading, Text, VStack } from '@chakra-ui/react';
import { PricingCard } from '../components/PricingCard';
import PropTypes from 'prop-types';

Pricing.propTypes = {
  onContactClick: PropTypes.func.isRequired
};

export function Pricing({ onContactClick }) {
  return (
    <Box id="pricing" py={{ base: 12, md: 24, lg: 32 }} bg="gray.50">
      <Container maxW="container.xl" px={4}>
        <VStack spacing={4} align="center" textAlign="center" mb={12}>
          <Heading size="2xl">Simple, Transparent Pricing</Heading>
          <Text color="gray.500" fontSize={{ md: 'xl' }} maxW="900px">
            Choose the plan that's right for you
          </Text>
        </VStack>
        <Grid
          templateColumns={{ base: '1fr', lg: 'repeat(3, 1fr)' }}
          gap={6}
          maxW="5xl"
          mx="auto"
        >
          <PricingCard
            title="Free"
            description="Perfect for trying out our service"
            price="$0"
            features={[
              'Up to 1 hour of audio/month',
              'Basic ASR model access',
              'Email support',
            ]}
            actionText="Get Started"
          />
          <PricingCard
            title="Pro"
            description="Perfect for professionals"
            price="$29"
            features={[
              'Unlimited audio transcription',
              'Advanced ASR model access',
              'Priority support',
            ]}
            isPrimary
            actionText="Start Free Trial"
          />
          <PricingCard
            title="Enterprise"
            description="Perfect for large organizations"
            price="Custom"
            features={[
              'Custom integration',
              'Dedicated support',
              'SLA guarantee',
            ]}
            actionText="Contact Sales"
            onAction={onContactClick}
          />
        </Grid>
      </Container>
    </Box>
  );
}