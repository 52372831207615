import React, { useLayoutEffect, useEffect, useState} from 'react';
import {  Redirect } from 'react-router-dom';
import Cookies from 'js-cookie';
import axios from 'axios';
import { backendRoot, verifyTokenPath } from '../backendInfo';
import { useApp } from '../contexts/AppProvider';
import Spinner from 'components/spinner/spinner';

// Protected Route Component
const ProtectedRoute = ({ children, path }) => {
  const ceo = useApp();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    verifyToken();
  }, []);

  const verifyToken = () => {
    const token = Cookies.get('token');
    if (token) {
      const url = `${backendRoot}${verifyTokenPath}`;
      axios
        .post(url, { token })
        .then((res) => {
          if (res.status === 200) {
            ceo.actions.setIsAuthenticated(1);
          }
        })
        .catch(() => {
          ceo.actions.setIsAuthenticated(0);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      ceo.actions.setIsAuthenticated(0);
      setLoading(false);
    }
  };

  if (loading) {
    return <Spinner />;
  }

  return ceo.states.isAuthenticated === 1 ? children : <Redirect to="/auth" />;
};

// Auth Route Component (for login page)
const AuthRoute = ({ children }) => {
  const ceo = useApp();
  const [loading, setLoading] = useState(true);
  console.log("Coming here")
  useEffect(() => {
    const verifyToken = () => {
      const token = Cookies.get('token');
      if (token) {
        const url = `${backendRoot}${verifyTokenPath}`;
        axios
          .post(url, { token })
          .then((res) => {
            if (res.status === 200) {
              ceo.actions.setIsAuthenticated(1);
            }
          })
          .catch(() => {
            console.log("error is verifying token for Authroute")
            ceo.actions.setIsAuthenticated(0);
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        ceo.actions.setIsAuthenticated(0);
        setLoading(false);
        console.log("No token found")
      }
    };

    verifyToken();
  }, []);

  if (loading) {
    return <Spinner />;
  }

  return ceo.states.isAuthenticated === 1 ? <Redirect to="/tasks" /> : children;
};

export { ProtectedRoute, AuthRoute };