import { Box, Container, Grid, Heading, HStack, Icon, Image, Text, VStack ,Link} from '@chakra-ui/react';
import { Star } from 'lucide-react';
import PropTypes from 'prop-types';

const testimonials = [
  {
    rating: 5,
    text: "Transcripter has revolutionized our podcast production process. The accuracy and speed of transcriptions are unmatched, saving us hours of work every week.",
    author: "Sarah Johnson",
    role: "Podcast Producer, Storytellers Media",
    image: "https://images.unsplash.com/photo-1494790108377-be9c29b29330?auto=format&fit=crop&w=100&h=100&q=80"
  },
  {
    rating: 5,
    text: "The multilingual support is a game-changer for our international research team. We can now transcribe interviews in multiple languages with ease and incredible accuracy.",
    author: "Dr. Michael Chen",
    role: "Research Scientist, Tech Innovations Lab",
    image: "https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?auto=format&fit=crop&w=100&h=100&q=80"
  }
];

export function Testimonials() {
  return (
    <Box id="testimonials" py={{ base: 12, md: 24, lg: 32 }}>
      <Container maxW="container.xl" px={4}>
        <VStack spacing={4} align="center" textAlign="center" mb={12}>
          <Heading size="2xl">What Our Customers Say</Heading>
          <Text color="gray.500" fontSize={{ md: 'xl' }} maxW="900px">
            Don't just take our word for it
          </Text>
        </VStack>
        <Grid
          templateColumns={{ base: '1fr', lg: 'repeat(2, 1fr)' }}
          gap={6}
          maxW="5xl"
          mx="auto"
        >
          {testimonials.map((testimonial, index) => (
            <Box key={index} p={6} boxShadow="md" rounded="lg" bg="white">
              <VStack align="start" spacing={4}>
                <HStack>
                  {Array.from({ length: testimonial.rating }).map((_, i) => (
                    <Icon key={i} as={Star} color="blue.500" fill="currentColor" />
                  ))}
                </HStack>
                <Text color="gray.600">{testimonial.text}</Text>
                <HStack spacing={4}>
                  <Image
                    src={testimonial.image}
                    alt={testimonial.author}
                    boxSize="48px"
                    rounded="full"
                    objectFit="cover"
                  />
                  <Box>
                    <Text fontWeight="medium">{testimonial.author}</Text>
                    <Text fontSize="sm" color="gray.500">{testimonial.role}</Text>
                  </Box>
                </HStack>
              </VStack>
            </Box>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}