import { Box, Card, CardBody, Icon, Text, VStack } from '@chakra-ui/react';
import { LucideIcon } from 'lucide-react';
import PropTypes from 'prop-types';


export function FeatureCard({ icon, title, description }) {
  return (
    <Card>
      <CardBody>
        <VStack spacing={2} align="center">
          <Icon as={icon} boxSize={12} color="blue.500" />
          <Text fontSize="xl" fontWeight="bold">{title}</Text>
          <Text fontSize="sm" color="gray.500" textAlign="center">
            {description}
          </Text>
        </VStack>
      </CardBody>
    </Card>
  );
}
FeatureCard.propTypes = {
  icon: PropTypes.elementType.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};