import { Box, Button, Container, Heading, HStack, Text, VStack } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useState } from 'react';  // Add this import
import { ContactModal } from '../components/ContactModal';  // Add this import

export function CTA({ onContactClick }) {
  const history = useHistory();
  const [isModalOpen, setIsModalOpen] = useState(false);  // Add this state

  return (
    <Box py={{ base: 12, md: 24, lg: 32 }}>
      <Container maxW="container.xl" px={4}>
        <VStack spacing={4} align="center" textAlign="center">
          <Heading size="2xl">
            Ready to Transform Your Audio Transcription Process?
          </Heading>
          <Text color="gray.500" fontSize={{ md: 'xl' }} maxW="900px">
            Start your free trial today. No credit card required.
          </Text>
          <HStack spacing={4}>
          <Button 
        size="lg" 
        colorScheme="blue"
        onClick={() => history.push('/auth')}
      >
        Start Your Free Trial
      </Button>
      <Button 
          size="lg" 
          variant="outline" 
          onClick={() => setIsModalOpen(true)}  // Update this onClick
        >
          Contact Sales
        </Button>
          </HStack>
        </VStack>
      </Container>
      <ContactModal 
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </Box>
  );
}

CTA.propTypes = {
  onContactClick: PropTypes.func.isRequired
};