import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Container,
  Heading,
  Text,
  VStack,
} from '@chakra-ui/react';
import PropTypes from 'prop-types';

const faqs = [
  {
    question: "What audio file types are supported?",
    answer: "We support a wide range of audio file types including MP3, WAV, M4A, FLAC, OGG, and AAC. Our system is designed to handle most common audio formats used in professional and personal settings."
  },
  {
    question: "How accurate are the transcriptions?",
    answer: "Our AI-powered transcriptions are highly accurate, typically achieving accuracy rates of 95% or higher for clear audio in supported languages. However, accuracy can vary depending on factors such as audio quality, accents, and background noise."
  },
  {
    question: "How long does transcription take?",
    answer: "Transcription time depends on the length of the audio and the current system load. Generally, we process audio at 2-3x real-time speed. For example, a 1-hour audio file typically takes 20-30 minutes to transcribe."
  },
  {
    question: "Can I integrate Transcripter with my existing tools?",
    answer: "Yes, we offer API access for Pro and Enterprise users, allowing you to integrate our transcription capabilities into your existing workflows and applications. Our RESTful API is well-documented and easy to implement."
  },
  {
    question: "How secure is my data?",
    answer: "We take data security very seriously. All uploads and transcriptions are encrypted in transit and at rest. We are GDPR compliant and offer data deletion options upon request. Our servers are hosted in secure, SOC 2 compliant data centers."
  }
];

export function FAQ() {
  return (
    <Box id="faq" py={{ base: 12, md: 24, lg: 32 }} bg="gray.50">
      <Container maxW="container.xl" px={4}>
        <VStack spacing={4} align="center" textAlign="center" mb={12}>
          <Heading size="2xl">Frequently Asked Questions</Heading>
          <Text color="gray.500" fontSize={{ md: 'xl' }} maxW="900px">
            Everything you need to know about our service
          </Text>
        </VStack>
        <Box maxW="3xl" mx="auto">
          <Accordion allowToggle>
            {faqs.map((faq, index) => (
              <AccordionItem key={index}>
                <h2>
                  <AccordionButton py={4}>
                    <Box flex="1" textAlign="left" fontWeight="medium">
                      {faq.question}
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  {faq.answer}
                </AccordionPanel>
              </AccordionItem>
            ))}
          </Accordion>
        </Box>
      </Container>
    </Box>
  );
}