export const backendRoot = "https://api.transcripter.ai" // without trailing slash
// export const backendRoot = "http://localhost:8000" // without trailing slash


export const googleLoginPath = "/accounts/google/login/?process=login"
export const verifyTokenPath = "/accounts/token/verify/"
export const logoutPath = "/accounts/logout/"

export const transPath = "/whisper/trans/"
export const uploadPath = "/whisper/upload/"

export const loginPath ="/users/login/"
export const registerPath="/users/register/"

export const downloadOutputPath ="/whisper/download-output"
export const signedUrlForUploadPath ="/whisper/generate-url-for-upload/"