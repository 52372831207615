import { Box, Flex, HStack, Link, Text } from '@chakra-ui/react';

export function Footer() {
  return (
    <Box as="footer" py={6} px={4} md={{ px: 6 }} borderTop="1px" borderColor="gray.200">
      <Flex direction={{ base: 'column', sm: 'row' }} gap={2} alignItems="center">
        <Text fontSize="xs" color="gray.500">© 2024 Transcripter. All rights reserved.</Text>
        <HStack spacing={4} sm={{ ml: 'auto', spacing: 6 }}>
          <Link fontSize="xs" _hover={{ textDecoration: 'underline', textUnderlineOffset: '4px' }} href="#">
            Terms of Service
          </Link>
          <Link fontSize="xs" _hover={{ textDecoration: 'underline', textUnderlineOffset: '4px' }} href="#">
            Privacy
          </Link>
          <Link fontSize="xs" _hover={{ textDecoration: 'underline', textUnderlineOffset: '4px' }} href="#">
            About Us
          </Link>
          <Link fontSize="xs" _hover={{ textDecoration: 'underline', textUnderlineOffset: '4px' }} href="#">
            Contact
          </Link>
        </HStack>
      </Flex>
    </Box>
  );
}