import { Box, Button, Container, Flex, Heading, HStack, Icon, Text, VStack } from '@chakra-ui/react';
import { CheckCircle } from 'lucide-react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import robotImage from '../static/landingpage_robot.png';

export function Hero() {
  const history = useHistory();

  return (
    <Box 
      py={{ base: 12, md: 24, lg: 32, xl: 48 }}
      bgGradient="linear(to-r, blue.500, blue.600)"
    >
      <Container maxW="container.xl" px={4}>
        <Flex gap={6} direction={{ base: 'column', lg: 'row' }}>
          <VStack 
            flex="1"
            align="flex-start"
            spacing={4}
            color="white"
          >
            <Text 
              px={2}
              py={1}
              bg="white"
              color="blue.500"
              rounded="full"
              fontSize="sm"
              fontWeight="medium"
            >
              New Release
            </Text>
            <Heading 
              size={{ base: 'xl', sm: '2xl', xl: '3xl' }}
              lineHeight="tight"
            >
              Effortlessly Transcribe Long-Form Audio into Any Language
            </Heading>
            <Text 
              fontSize={{ base: 'md', md: 'xl' }}
              color="whiteAlpha.900"
              maxW="600px"
            >
              Transcribe audio of any length, in any language, with unparalleled accuracy—powered by cutting-edge ASR technologies.
            </Text>
            <HStack spacing={4}>
              <Button 
        size="lg" 
        bg="white" 
        color="blue.500" 
        _hover={{ bg: 'gray.100' }}
        onClick={() => history.push('/auth')}
      >
        Try for Free
      </Button>
              <Button size="lg" variant="outline" color="white" borderColor="white" _hover={{ bg: 'whiteAlpha.200' }}>
                View Pricing
              </Button>
            </HStack>
            <HStack spacing={6} fontSize="sm" color="whiteAlpha.900">
              <HStack>
                <Icon as={CheckCircle} />
                <Text>No credit card required</Text>
              </HStack>
              <HStack>
                <Icon as={CheckCircle} />
                <Text>14-day free trial</Text>
              </HStack>
            </HStack>
          </VStack>
          <Box 
            flex="1"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Box
              as="img"
              src={robotImage}
              alt="AI-powered transcription illustration"
              rounded="xl"
              width="full"
              maxW="600px"
              height="auto"
              objectFit="cover"
            />
          </Box>
        </Flex>
      </Container>
    </Box>
  );
}