
import React, {useEffect} from "react";
// import TranscribeForm from 'components/TranscribeForm';

import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import AuthLayout from 'layouts/auth';
import AdminLayout from 'layouts/admin';
import TaskLayout from 'layouts/tasks';
import PaymentSuccess from "views/payments/success";
import PaymentCancel from "views/payments/cancel";
import { Box, ChakraProvider, Flex, useColorMode } from '@chakra-ui/react';
import { useState } from 'react';
import { ContactModal } from './components/ContactModal';
import { Footer } from './components/Footer';
import { Header } from './components/Header';
import { Hero } from './sections/Hero';
import { Features } from './sections/Features';
import { Pricing } from './sections/Pricing';
import { Testimonials } from './sections/Testimonials';
import { FAQ } from './sections/FAQ';
import { CTA } from './sections/CTA';

import { ProtectedRoute, AuthRoute } from 'hooks/Auth';

function App() {

  const [isContactModalOpen, setIsContactModalOpen] = useState(false);
  const { setColorMode } = useColorMode();

  useEffect(() => {
    // because dark version was looking bad
    const path = window.location.pathname;
    if (path === '/') {
      setColorMode('light');
    }
  }, []);
  // Landing page route component
  const LandingPage = () => {

    return (
      <Flex direction="column" minH="100vh">
        <Header />
        <Box as="main" flex="1">
          <Hero />
          <Features />
          <Pricing onContactClick={() => setIsContactModalOpen(true)} />
          <Testimonials />
          <FAQ />
          <CTA onContactClick={() => setIsContactModalOpen(true)} />
        </Box>
        <Footer />
        <ContactModal
          isOpen={isContactModalOpen}
          onClose={() => setIsContactModalOpen(false)}
        />
      </Flex>
    );
  };

  return (
    <ChakraProvider>
      <Router>
        <Switch>
          {/* Public route for landing page */}
          <Route exact path="/">
            <LandingPage/>
          </Route>

          {/* Auth route - redirects to /tasks if already authenticated */}
          <Route path="/auth/:path?">
            <AuthRoute>
              <AuthLayout />
            </AuthRoute>
          </Route>

          {/* Protected routes - redirect to /auth if not authenticated */}
          <Route path="/admin">
            <ProtectedRoute>
              <AdminLayout />
            </ProtectedRoute>
          </Route>

          <Route path="/tasks">
            <ProtectedRoute>
              <TaskLayout />
            </ProtectedRoute>
          </Route>

          <Route path="/payments/success">
            <ProtectedRoute>
              <PaymentSuccess />
            </ProtectedRoute>
          </Route>

          <Route path="/payments/failed">
            <ProtectedRoute>
              <PaymentCancel />
            </ProtectedRoute>
          </Route>
        </Switch>
      </Router>
    </ChakraProvider>
  );
}

export default App;
