import { Box, Flex, HStack, Link as ChakraLink, Text } from '@chakra-ui/react';
import { Link } from 'react-router-dom';  // Add this import
import { FileText } from 'lucide-react';

export function Header() {
  // const history = useHistory();
  return (
    <Box as="header" borderBottom="1px" borderColor="gray.200" height="16" px={4} lg={{ px: 6 }}>
      <Flex h="full" alignItems="center">
        <Link href="/" display="flex" alignItems="center" _hover={{ textDecoration: 'none' }}>
          <FileText size={24} />
          <Text ml={2} fontSize="lg" fontWeight="bold">Transcripter</Text>
        </Link>
        <HStack as="nav" ml="auto" spacing={4} sm={{ spacing: 6 }}>
          <Link href="#features" fontSize="sm" fontWeight="medium" _hover={{ textDecoration: 'underline', textUnderlineOffset: '4px' }}>
            Features
          </Link>
          <Link href="#pricing" fontSize="sm" fontWeight="medium" _hover={{ textDecoration: 'underline', textUnderlineOffset: '4px' }}>
            Pricing
          </Link>
          <Link href="#faq" fontSize="sm" fontWeight="medium" _hover={{ textDecoration: 'underline', textUnderlineOffset: '4px' }}>
            FAQ
          </Link>
          <ChakraLink 
            as={Link} 
            to="/auth" 
            fontSize="sm" 
            fontWeight="medium"
            px={4}
            py={2}
            _hover={{ bg: 'gray.100' }}
          >
            Login
          </ChakraLink>
        </HStack>
      </Flex>
    </Box>
  );
}