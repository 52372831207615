import { Box, Container, Grid, Heading, Text, VStack } from '@chakra-ui/react';
import { Globe2, FileText, Zap, Edit3, Lock, Cpu } from 'lucide-react';
import { FeatureCard } from '../components/FeatureCard';
import PropTypes from 'prop-types';

const features = [
  {
    icon: Globe2,
    title: 'Multilingual Support',
    description: 'Transcribe into over 100 languages and dialects with ease.',
  },
  {
    icon: FileText,
    title: 'Long-Form Audio',
    description: 'Process hours-long audio files without compromising quality.',
  },
  {
    icon: Zap,
    title: 'Fast Turnaround',
    description: 'Quick processing times without compromising accuracy.',
  },
  {
    icon: Edit3,
    title: 'Customizable Outputs',
    description: 'Choose formats, timestamps, speaker identification, and more.',
  },
  {
    icon: Lock,
    title: 'Secure and Confidential',
    description: 'End-to-end encryption ensures your data is protected.',
  },
  {
    icon: Cpu,
    title: 'Advanced ASR',
    description: 'Utilizes cutting-edge models for unparalleled accuracy.',
  },
];

export function Features() {
  return (
    <Box id="features" py={{ base: 12, md: 24, lg: 32 }}>
      <Container maxW="container.xl" px={4}>
        <VStack spacing={4} align="center" textAlign="center" mb={12}>
          <Heading size="2xl">Why Choose Transcripter?</Heading>
          <Text color="gray.500" fontSize={{ md: 'xl' }} maxW="900px">
            Experience the future of audio transcription with our advanced features
          </Text>
        </VStack>
        <Grid
          templateColumns={{ base: '1fr', md: 'repeat(2, 1fr)', lg: 'repeat(3, 1fr)' }}
          gap={6}
          maxW="5xl"
          mx="auto"
        >
          {features.map((feature, index) => (
            <FeatureCard key={index} {...feature} />
          ))}
        </Grid>
      </Container>
    </Box>
  );
}