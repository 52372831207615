import { Button, Card, CardBody, Icon, Text, VStack } from '@chakra-ui/react';
import { CheckCircle } from 'lucide-react';
import PropTypes from 'prop-types';


export function PricingCard({
  title,
  description,
  price,
  features,
  isPrimary,
  onAction,
  actionText,
}) {
  return (
    <Card borderColor={isPrimary ? 'blue.500' : undefined}>
      <CardBody>
        <VStack spacing={4} align="center">
          <Text fontSize="xl" fontWeight="bold">{title}</Text>
          <Text fontSize="sm" color="gray.500">{description}</Text>
          <Text fontSize="4xl" fontWeight="bold">{price}</Text>
          <Button width="full" onClick={onAction}>
            {actionText}
          </Button>
          <VStack spacing={2} align="start" width="full">
            {features.map((feature, index) => (
              <Text key={index} fontSize="sm" display="flex" alignItems="center">
                <Icon as={CheckCircle} mr={2} color="blue.500" boxSize={4} />
                {feature}
              </Text>
            ))}
          </VStack>
        </VStack>
      </CardBody>
    </Card>
  );
}
PricingCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  features: PropTypes.arrayOf(PropTypes.string).isRequired,
  isPrimary: PropTypes.bool,
  onAction: PropTypes.func,
  actionText: PropTypes.string.isRequired,
};